//helper function
function $$(selector, context) {
    context = context || document;
    const elements = context.querySelectorAll(selector);
    return Array.prototype.slice.call(elements);
}

window.addEventListener('scroll', function() {
    const scrolledHeight= window.pageYOffset;
    $$('.parallax').forEach(function(el,index,array) {
        const limit = el.offsetTop + el.offsetHeight;
        const ratio = el.getAttribute('data-parallax-ratio');
        const defaultRatio = 5;
        const calculationRatio = ratio !== null ? parseInt(ratio) : defaultRatio;
        if(scrolledHeight > el.offsetTop && scrolledHeight <= limit) {
            el.style.backgroundPositionY = (scrolledHeight - el.offsetTop) / calculationRatio + 'px';
        }
        else {
            el.style.backgroundPositionY = '0';
        }
    });
});
