import Sizzle from 'sizzle';

const dataScrollElement = Sizzle('[data-scroll-to]');
const defaultDuration = 350;
const defaultOffset = 32;

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
		  window.webkitRequestAnimationFrame ||
		  window.mozRequestAnimationFrame    ||
		  function( callback ){
			window.setTimeout(callback, 1000 / 60);
		  };
})();

function scrollToY(elementY, speed = defaultDuration, easing = 'easeOutSine', offset = defaultOffset) {

	const scrollTargetY = elementY - offset;

	const scrollY = window.pageYOffset;
	let currentTime = 0;

	const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

	// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
	const PI_D2 = Math.PI / 2;
	const easingEquations = {
		easeOutSine: function (pos) {
			return Math.sin(pos * (Math.PI / 2));
		},
		easeInOutSine: function (pos) {
			return (-0.5 * (Math.cos(Math.PI * pos) - 1));
		},
		easeInOutQuint: function (pos) {
			if ((pos /= 0.5) < 1) {
				return 0.5 * Math.pow(pos, 5);
			}
			return 0.5 * (Math.pow((pos - 2), 5) + 2);
		}
	};
	function tick() {
		currentTime += 1 / 60;

		const p = currentTime / time;
		const t = easingEquations[easing](p);

		if (p < 1) {
			requestAnimFrame(tick);

			window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
		} else {
			window.scrollTo(0, scrollTargetY);
		}
	}

	// call it once to get started
	tick();
}


const createFakeAnchorAndClickIt = (url) => {
	const link = document.createElement('a');
	link.href = url;
	// Some browsers require the link to be appended to the DOM.
	// Otherwise calling click() event on the virtual anchor provided
	// with document.createElement() will not trigger.
	// Positioning the appended element out of the viewbox will also
	// prevent undesired visual space in the DOM.
	link.style.cssText = 'position: fixed;left: -2000em;opacity: 0;visibility: hidden;top: -2000em;';
	const body = document.body !== null ? document.body : null;
	if (body !== null) {
		body.appendChild(link);
	}
	link.click();
	// Added null timeout to remove child so it happens in different thread.
	// Some browsers may remove the element earlier than invoking the click action.
	setTimeout(() => {
		if (body !== null) {
			body.removeChild(link);
		}
	}, 0);
}

const removeDataScrollFromUrl = (urlParameters) => {
	// remove scroll-to from get parameters
	urlParameters.delete('scroll-to');
	const urlParametersString = urlParameters.toString();
	window.history.replaceState({}, document.title, `${window.location.pathname}${urlParametersString.length > 0 ? '?' : ''}${urlParameters.toString()}`);
};

const scrollToElement = (event) => {
	if (typeof event !== 'undefined' && event.type === 'click') {
		event.preventDefault();
		const scrollTarget = Sizzle(event.target.getAttribute('data-scroll-to'));
		if (scrollTarget.length) {
			const firstTarget = scrollTarget[0];
			const firstTargetTopPosition = firstTarget.offsetTop;
			const duration = event.target.getAttribute('data-scroll-to-duration');
			const offset = event.target.getAttribute('data-scroll-to-offset');
			scrollToY(firstTargetTopPosition, duration !== null ? parseInt(duration) : defaultDuration, 'easeInOutSine', offset !== null ? parseInt(offset) : defaultOffset);
		} else {
			const elementHref = event.target.getAttribute('href');
			if (elementHref !== null) {
				createFakeAnchorAndClickIt(elementHref);
			}
		}
	} else {
		if (typeof window.URLSearchParams === 'function') {
			const urlParameters = new URLSearchParams(window.location.search);
			const urlScrollTarget = urlParameters.getAll('scroll-to');
			if (urlScrollTarget !== null && Array.isArray(urlScrollTarget) && urlScrollTarget.length > 0) {
				const firstScrollTarget = urlScrollTarget[0];
				const firstTarget = Sizzle(firstScrollTarget)[0];
				const firstTargetTopPosition = firstTarget.getBoundingClientRect().top;
				scrollToY(firstTargetTopPosition, defaultDuration, 'easeInOutSine', defaultOffset);
				setTimeout(() => {
					removeDataScrollFromUrl(urlParameters);
				}, defaultDuration);
			}
		}
	}
}

if (dataScrollElement.length) {
	dataScrollElement.forEach((element) => {
		element.addEventListener('click', scrollToElement);
	});
}

window.onload = scrollToElement;
