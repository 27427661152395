import Sizzle from 'sizzle';

const faqQuestions = Sizzle('.faq__question');
const faqQuestionActiveClass = 'faq__question--active';

const setAnswerHeight = (question, shouldSetHeightToNull = false) => {
	const answer = question.nextElementSibling;
	if (answer !== null) {
		const answerChildren = answer.children;
		if (answerChildren.length) {
			if (shouldSetHeightToNull === false) {
				const firstChildrenHeight = answer.children[0].offsetHeight;
				answer.style.maxHeight = firstChildrenHeight + 'px';
			} else {
				answer.style.maxHeight = 0;
			}
		}
	}
}

const setAnswerActive = (question) => {
	question.classList.add(faqQuestionActiveClass);
	setAnswerHeight(question);
}

const setAnswerInactive = (question) => {
	question.classList.remove(faqQuestionActiveClass);
	setAnswerHeight(question, true);
}

const maintainAnswersHeight = () => {
	if (faqQuestions.length) {
		faqQuestions.map((question) => {
			if (question.classList.contains(faqQuestionActiveClass) === true) {
				setAnswerHeight(question);
			}
		});
	}
};

if (faqQuestions.length) {
	window.addEventListener('resize', maintainAnswersHeight);

	faqQuestions.map((question) => {
		question.addEventListener('click', () => {
			if (question.classList.contains(faqQuestionActiveClass) === true) {
				setAnswerInactive(question);
			} else {
				setAnswerActive(question);
			}
		});
	});
}
