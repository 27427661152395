import Sizzle from 'sizzle';

const navbar = Sizzle('.navbar');
const navbarToggle = Sizzle('.navbar__toggle');
const navbarLogo = Sizzle('.navbar__logo');
const navbarMobileContent = Sizzle('.navbar__content-mobile');
const navbarMobileLink = Sizzle('.navbar__content-mobile-link');
const body = Sizzle('body');
const content = Sizzle('.content');
const activePageIndicatorLinks = Sizzle('[data-active-page-link]');

let lastYOffset = window.pageYOffset;

const getNavbarOffset = (navbar) => {
	if (navbar.length) {
		return navbar[0].offsetHeight;
	}

	return 0;
}

const toggleMenu = (event) => {
	event.preventDefault();
	if (navbar.length) {
		navbar[0].classList.toggle('navbar--active');
	}
	if (navbarToggle.length) {
		navbarToggle[0].classList.toggle('navbar__toggle--active');
	}

	if (navbarLogo.length) {
		navbarLogo[0].classList.toggle('navbar__logo--gone');
	}

	if (navbarMobileContent.length) {
		navbarMobileContent[0].classList.toggle('navbar__content-mobile--active');
	}

	if (content.length) {
		content[0].classList.toggle('content--bluried');
	}

	if (body.length) {
		body[0].classList.toggle('overflow-hidden-mobile');
	}
}

const handleNavbarRevelation = () => {
	if (navbar.length) {
		const navbarOffset = getNavbarOffset(navbar);
		const pageYOffset = window.pageYOffset;
		if ((pageYOffset > navbarOffset) && !navbar[0].classList.contains('navbar--active') && pageYOffset > lastYOffset) {
			navbar[0].classList.add('navbar--gone');
			navbar[0].classList.remove('navbar--unveiled');
		} else {
			navbar[0].classList.remove('navbar--gone');
			if ((pageYOffset > navbarOffset)) {
				navbar[0].classList.add('navbar--unveiled');
			} else {
				navbar[0].classList.remove('navbar--unveiled');
			}
		}
		lastYOffset = window.pageYOffset;
	}
};

if (navbarToggle.length) {
	navbarToggle[0].addEventListener('click', toggleMenu);
}

if (navbarMobileLink.length) {
	navbarMobileLink.forEach((link) => link.addEventListener('click', toggleMenu));
}

if (navbar.length) {
	handleNavbarRevelation();
	window.addEventListener('scroll', handleNavbarRevelation);
}

if (activePageIndicatorLinks.length) {
	const currentUrl = window.location.toString();
	activePageIndicatorLinks.forEach((link) => {
		const linkHref = link.getAttribute('href');
		if(currentUrl.indexOf(linkHref) !== -1) {
			link.classList.add('active');
		}
	});
}
