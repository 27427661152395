import Sizzle from 'sizzle';

const tooltipElement = Sizzle('.tooltip-owner');

if (tooltipElement.length) {
	tooltipElement.forEach((tooltip) => {
		if (window.matchMedia('(hover: none)').matches === true) {
			tooltip.addEventListener('click', (event) => {
				tooltip.classList.toggle('tooltip-owner-active');
			});
		}
	});
}
